<template>
  <div class="sora-sender">
    <p>
      CameraID
      <input type="text" v-model="this.cameraId" />
      <button @click="startConn">接続</button>
      <button @click="closeConn">切断</button>
    </p>
    <p>{{ devices }}</p>
    <div>
      <div class="video_wrapper">
        <video
          ref="video"
          :srcObject="stream"
          playsinline
          autoplay
          muted
          loop
          controls
          style="width: 100%; height: 100%; border: 1px solid black"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import Sora from "sora-js-sdk";
import axios from "axios";
export default {
  name: "SoraView",
  props: {
    id: {
      type: String,
      defualt: undefined,
    },
  },
  data() {
    return {
      cameraId: this.id,
      conn: undefined,
      stream: undefined,
      info: undefined,
      devices: undefined,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.cameraId != undefined) {
        this.startConn();
      }
    });
  },
  methods: {
    async startConn() {
      if (this.cameraId === undefined) {
        return;
      }
      if (this.conn) {
        this.conn.disconnect();
        this.conn = undefined;
      }
      const url = "https://api.rocs-lab.com/sora_cloud/" + this.cameraId;
      await axios
        .get(url, { timeout: 20000 })
        .then((res) => {
          this.info = res.data;
        })
        .catch((e) => {
          console.error(e);
        });
      const channelId = this.info.Item.channel_id;
      const debug = true;
      const sora = Sora.connection("wss://sora.sora-cloud.shiguredo.app/signaling", debug);
      const metadata = { access_token: this.info.Item.access_token };
      const options = {
        multistream: true,
        dataChannelSignaling: true,
        ignoreDisconnectWebSocket: true,
        videoBitRate: 900,
        audioBitRate: 25,
        videoCodecType: "VP9",
      };
      this.conn = sora.sendonly(channelId, metadata, options);
      this.conn.on("notify", (message, transportType) => {
        console.log(message, transportType);
      });
      this.conn.on("timelime", function (event) {
        console.log(event);
      });
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        var videoDevices = [0, 0];
        var videoDeviceIndex = 0;
        devices.forEach(function (device) {
          console.log(
            device.kind + ": " + device.label + " id = " + device.deviceId
          );
          if (device.kind == "videoinput") {
            videoDevices[videoDeviceIndex++] = device.deviceId;
          }
        });
        this.devices = videoDevices;
        var constraints = {
          width: { min: 640, ideal: 1280, max: 1280 },
          height: { min: 360, ideal: 720, max: 720 },
          frameRate: { min:10, ideal: 20, max: 30 },
          /*focusDistance: { min: 0.05, ideal: 0.5, max: 1.0 },
          deviceId: { exact: videoDevices[1] },*/
          facingMode: { exact: "environment" },
        };
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ video: constraints, audio: true })
            .then((stream) => {
              this.stream = stream;
              this.conn.connect(this.stream).catch((e) => {
                console.error(e);
              });
            });
        }
      });
      console.log("ok");
    },
    closeConn() {
      if (this.conn === undefined) {
        return;
      }
      this.conn.disconnect();
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
