<template>
  <div class="sender">
    <SoraSender :id="this.id" />
  </div>
</template>

<script>
import SoraSender from "@/components/SoraSender.vue";

export default {
  name: "Sender",
  props: ["id"],
  components: {
    SoraSender,
  },
};
</script>
