import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";
// import Live from "../views/Live.vue";
// import View from "../views/View.vue";
// import LocalView from "../views/LocalView.vue";
import Sender from "../views/Sender.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Sender,
  },
  /*
  {
    path: "/live/:id",
    name: "Live",
    component: Live,
    props: true,
  },
  {
    path: "/view/:id",
    name: "View",
    component: View,
    props: true,
  },
  {
    path: "/local/view/:id",
    name: "LocalView",
    component: LocalView,
    props: true,
  },
  */
  {
    path: "/sender/:id",
    name: "Sender",
    component: Sender,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
